import { z } from "zod";
import { route } from "./route";

const zone = z.enum(["EXO", "STD", "EU", "BREXIT", "HT"]);
const mode = z.enum(["mobile", "ftth"]);
const flow = z.enum(["multiOrder"]);

export const portalRoutes = {
  index: route("/"),
  komorkowy: route("/komorkowy"),
  regulaminyCenniki: route("/regulaminy-cenniki"),
  regulaminyCennikiArchiwalne: route("/regulaminy-cenniki-archiwalne"),
  politykaPrywatnosci: route("/polityka-prywatnosci"),
  czyZachowacNumer: route(
    "/czy-zachowac-numer",
    z.object({
      mode,
      oferta: z.string().optional(),
      type: z.literal("registration").optional(),
      userId: z.string().optional(),
    })
  ),
  dostepOgraniczony: route("/dostep-ograniczony"),
  mojeKonto: {
    index: route(
      "/moje-konto",
      z
        .object({
          mode: z.enum(["mobile", "ftth"]).optional(),
          flow: flow.optional(),
          userId: z.string().optional(),
        })
        .optional()
    ),
    uslugiDodatkowe: {
      index: route(
        "/moje-konto/uslugi-dodatkowe",
        z.object({
          userId: z.string().optional(),
        })
      ),
      pakietGb: route(
        "/moje-konto/uslugi-dodatkowe/pakiet-gb",
        z.object({
          userId: z.string().optional(),
        })
      ),
      roaming: {
        index: route(
          "/moje-konto/uslugi-dodatkowe/roaming",
          z
            .object({
              userId: z.string().optional(),
              country: z.string().optional(),
            })
            .optional()
        ),
        podsumowanie: route(
          "/moje-konto/uslugi-dodatkowe/roaming/podsumowanie",
          z.object({
            zone,
            zoneName: z.string().optional(),
            userId: z.string().optional(),
            country: z.string(),
            voicePackageId: z.string().optional(),
            dataPackageId: z.string().optional(),
          })
        ),
        strefy: route(
          "/moje-konto/uslugi-dodatkowe/roaming/strefy",
          z
            .object({
              zone: zone.optional(),
              country: z.string().optional(),
              userId: z.string().optional(),
            })
            .optional()
        ),
      },
      rozmowyMiedzynarodowe: {
        index: route(
          "/moje-konto/uslugi-dodatkowe/rozmowy-miedzynarodowe",
          z.object({
            userId: z.string().optional(),
          })
        ),
        podsumowanie: route(
          "/moje-konto/uslugi-dodatkowe/rozmowy-miedzynarodowe/podsumowanie",
          z.object({
            zone,
            zoneName: z.string().optional(),
            country: z.string(),
            userId: z.string().optional(),
          })
        ),
        strefy: route(
          "/moje-konto/uslugi-dodatkowe/rozmowy-miedzynarodowe/strefy",
          z
            .object({
              zone: zone.optional(),
              country: z.string().optional(),
              userId: z.string().optional(),
            })
            .optional()
        ),
      },
    },
    mojProfil: {
      index: route("/moje-konto/moj-profil"),
      zmienHaslo: route("/moje-konto/moj-profil/zmien-haslo"),
      zmienAdresEmail: {
        index: route("/moje-konto/moj-profil/zmien-adres-email"),
        blad: route("/moje-konto/moj-profil/zmien-adres-email/blad"),
        wiadomoscWyslana: route(
          "/moje-konto/moj-profil/zmien-adres-email/wiadomosc-wyslana"
        ),
      },
    },
    platnosci: {
      index: route(
        "/moje-konto/platnosci",
        z
          .object({
            procesReaktywacji: z.string().optional(),
            type: z.string().optional(),
            mode: z.string().optional(),
            zmianaMetodyPlatnosci: z.literal("1").optional(),
            userId: z.string().optional(),
          })
          .optional()
      ),
      bladPlatnosci: route("/moje-konto/platnosci/blad-platnosci"),
      dodajSrodki: route(
        "/moje-konto/platnosci/dodaj-srodki",
        z
          .object({
            mode: z.string().optional(),
            procesReaktywacji: z.string().optional(),
            zmianaMetodyPlatnosci: z.literal("1").optional(),
            userId: z.string().optional(),
          })
          .optional()
      ),
      faktury: route(
        "/moje-konto/platnosci/faktury",
        z
          .object({
            mode: z.string().optional(),
            zmianaMetodyPlatnosci: z.literal("1").optional(),
            userId: z.string().optional(),
          })
          .optional()
      ),
      historia: route(
        "/moje-konto/platnosci/historia",
        z
          .object({
            mode: z.string().optional(),
            ostatniaNieudanaPlatnosc: z.literal("1").optional(),
            zmianaMetodyPlatnosci: z.literal("1").optional(),
            userId: z.string().optional(),
          })
          .optional()
      ),
      wyplacSrodki: route(
        "/moje-konto/platnosci/wyplac-srodki",
        z.object({
          zmianaMetodyPlatnosci: z.literal("1").optional(),
          mode: z.string().optional(),
        })
      ),
      zaplac: route("/moje-konto/platnosci/zaplac"),
      zarzadzajKarta: route(
        "/moje-konto/platnosci/zarzadzaj-karta",
        z.object({
          id: z.string(),
          procesReaktywacji: z.string().optional(),
          mode: z.string().optional(),
          userId: z.string().optional(),
        })
      ),
      zmianaMetody: route(
        "/moje-konto/platnosci/zmiana-metody",
        z.object({
          id: z.string(),
          type: z.enum(["CREDIT_CARD", "DIGITAL_WALLET", "BLIK"]),
          procesReaktywacji: z.string().optional(),
          mode: z.string().optional(),
          userId: z.string().optional(),
          zmianaMetodyPlatnosci: z.literal("1").optional(),
        })
      ),
    },
    pomoc: {
      index: route("/moje-konto/pomoc"),
      faq: route(
        "/moje-konto/pomoc/faq",
        z.object({ category: z.string(), article: z.string() }).optional()
      ),
    },
    zmienTerminInstalacji: route("/moje-konto/zmien-termin-instalacji"),
    wygenerujNoweHaslo: route("/moje-konto/wygeneruj-nowe-haslo"),
    niewystarczajacaKwotaNaKoncie: route(
      "/moje-konto/niewystarczajaca-kwota-na-koncie",
      z.object({ mode }).optional()
    ),
    bladPlatnosci: route(
      "/moje-konto/blad-platnosci",
      z.object({ mode, userId: z.string().optional() }).optional()
    ),
    anulujZamowienie: route(
      "/moje-konto/anuluj-zamowienie",
      z.object({
        mode,
        flow: flow.optional(),
        userId: z.string().optional(),
      })
    ),
    dodajKarteSim: route(
      "/moje-konto/dodaj-karte-sim",
      z.object({ mode, userId: z.string().optional() }).optional()
    ),
    dodajPelnySim: route(
      "/moje-konto/dodaj-pelny-sim",
      z.object({ mode }).optional()
    ),
    mojaKarta: {
      index: route(
        "/moje-konto/moja-karta",
        z.object({ userId: z.string().optional() }).optional()
      ),
      blokowanieKartySim: route(
        "/moje-konto/moja-karta/blokowanie-karty-sim",
        z.object({ userId: z.string().optional() }).optional()
      ),
      odblokowanieKartySim: route(
        "/moje-konto/moja-karta/odblokowanie-karty-sim",
        z.object({ userId: z.string().optional() }).optional()
      ),
      ustawieniaNumeru: route(
        "/moje-konto/moja-karta/ustawienia-numeru",
        z.object({ userId: z.string().optional() }).optional()
      ),
      zmienNumer: route(
        "/moje-konto/moja-karta/zmien-numer",
        z.object({ userId: z.string().optional() }).optional()
      ),
      wymianaKarty: {
        index: route(
          "/moje-konto/moja-karta/wymiana-karty",
          z.object({ userId: z.string().optional() }).optional()
        ),
        esim: {
          potwierdzDane: route(
            "/moje-konto/moja-karta/wymiana-karty/esim/potwierdz-dane",
            z.object({ userId: z.string().optional() }).optional()
          ),
          kartaGotowa: route(
            "/moje-konto/moja-karta/wymiana-karty/esim/karta-gotowa",
            z.object({ userId: z.string().optional() }).optional()
          ),
          podsumowanie: route(
            "/moje-konto/moja-karta/wymiana-karty/esim/podsumowanie",
            z.object({ userId: z.string().optional(), mode }).optional()
          ),
          zamowienieZlozone: route(
            "/moje-konto/moja-karta/wymiana-karty/esim/zamowienie-zlozone",
            z.object({ userId: z.string().optional() }).optional()
          ),
        },
        sim: {
          dodajKarte: route(
            "/moje-konto/moja-karta/wymiana-karty/sim/dodaj-karte",
            z.object({ userId: z.string().optional() }).optional()
          ),
          adresDostawy: route(
            "/moje-konto/moja-karta/wymiana-karty/sim/adres-dostawy",
            z.object({ userId: z.string().optional() }).optional()
          ),
          potwierdzDane: route(
            "/moje-konto/moja-karta/wymiana-karty/sim/potwierdz-dane",
            z.object({ userId: z.string().optional() }).optional()
          ),
          podsumowanie: route(
            "/moje-konto/moja-karta/wymiana-karty/sim/podsumowanie",
            z.object({ userId: z.string().optional(), mode }).optional()
          ),
          zamowienieZlozone: route(
            "/moje-konto/moja-karta/wymiana-karty/sim/zamowienie-zlozone",
            z.object({ userId: z.string().optional() }).optional()
          ),
        },
      },
    },
    platnosc: {
      index: route(
        "/moje-konto/platnosc",
        z
          .object({
            mode,
            userId: z.string().optional(),
          })
          .optional()
      ),
      bladPlatnosciOnline: route(
        "/moje-konto/platnosc/blad-platnosci-online",
        z.object({ mode }).optional()
      ),
      blik: {
        index: route(
          "/moje-konto/platnosc/blik",
          z.object({ mode }).optional()
        ),
        potwierdz: route(
          "/moje-konto/platnosc/blik/potwierdz",
          z.object({ id: z.string(), mode })
        ),
      },
    },
    mojeUslugi: {
      index: route(
        "/moje-konto/moje-uslugi",
        z
          .object({
            mode: z.enum(["mobile", "ftth"]).optional(),
            userId: z.string().optional(),
          })
          .optional()
      ),
      reaktywacja: {
        index: route(
          "/moje-konto/moje-uslugi/reaktywacja",
          z.object({ mode, userId: z.string().optional() })
        ),
        czasMinal: route("/moje-konto/moje-uslugi/reaktywacja/czas-minal"),
        uslugaReaktywowana: route(
          "/moje-konto/moje-uslugi/reaktywacja/usluga-reaktywowana",
          z.object({ userId: z.string().optional(), mode })
        ),
      },
      zawiesUsluge: {
        index: route("/moje-konto/moje-uslugi/zawies-usluge"),
        czasMinal: route("/moje-konto/moje-uslugi/zawies-usluge/czas-minal"),
      },
      zrezygnujZUslugi: route(
        "/moje-konto/moje-uslugi/zrezygnuj-z-uslugi",
        z.object({ mode, userId: z.string().optional() }).optional()
      ),
    },
    anulujPrzeniesienie: {
      index: route(
        "/moje-konto/port-out/anuluj-przeniesienie",
        z.object({ mode, userId: z.string().optional() }).optional()
      ),
    },
    dodajKodPoufny: route(
      "/moje-konto/dodaj-kod-poufny",
      z
        .object({
          mode,
          userId: z.string().optional(),
        })
        .optional()
    ),
    zmienKodPoufny: route(
      "/moje-konto/zmien-kod-poufny",
      z
        .object({
          userId: z.string().optional(),
        })
        .optional()
    ),
    zamowienieZlozone: route(
      "/moje-konto/zamowienie-zlozone",
      z
        .object({
          mode,
        })
        .optional()
    ),
  },
  obsluga: {
    cookies: route("/obsluga/cookies"),
  },
  platnosc: {
    index: route("/platnosc"),
    karta: route("/platnosc/karta"),
    bladDodaniaSrodkow: route("/platnosc/blad-dodania-srodkow"),
    blik: {
      index: route("/platnosc/blik"),
      potwierdz: route(
        "/platnosc/blik/potwierdz",
        z.object({ id: z.string() })
      ),
    },
  },
  sprawdzDostepnosc: {
    index: route("/sprawdz-dostepnosc"),
    uslugaDostepna: route("/sprawdz-dostepnosc/usluga-dostepna"),
    uslugaNiedostepna: route("/sprawdz-dostepnosc/usluga-niedostepna"),
  },
  weryfikacjaNumeru: {
    index: route("/weryfikacja-numeru", z.object({ mode: z.literal("ftth") })),
    otp: route(
      "/weryfikacja-numeru/otp",
      z.object({ mode: z.literal("ftth") })
    ),
  },
  weryfikacjaTozsamosci: {
    index: route(
      "/weryfikacja-tozsamosci",
      z.object({
        mode,
        oferta: z.string().optional(),
        type: z.literal("registration").optional(),
      })
    ),
    zdjecieDokumentu: route(
      "/weryfikacja-tozsamosci/zdjecie-dokumentu",
      z.object({
        mode,
        redirect: z.enum(["IDENTITY.onfido"]),
      })
    ),
    mojeId: {
      blad: route("/weryfikacja-tozsamosci/moje-id/blad"),
    },
    technik: route(
      "/weryfikacja-tozsamosci/technik",
      z.object({
        mode,
      })
    ),
  },
  zaloguj: {
    index: route(
      "/zaloguj",
      z
        .object({
          mode,
          flow: z.enum(["ONBOARDING", "multiOrder"]),
          oferta: z.string(),
          amount: z.string().optional(),
        })
        .optional()
    ),
    blad: route("/zaloguj/blad"),
    kontynuuj: route(
      "/zaloguj/kontynuuj",
      z.object({
        mode,
        redirect: z.enum(["IDENTITY.onfido"]),
        _t: z.string(),
      })
    ),
  },
  zmianaHasla: {
    index: route("/zmiana-hasla"),
    potwierdzenie: route("/zmiana-hasla/potwierdzenie"),
  },
  flags: route("/flags"),
  podsumowanie: route(
    "/podsumowanie",
    z.object({
      mode,
    })
  ),
  pomoc: route("/pomoc"),
  swiatlowod: route("/swiatlowod"),
  terminInstalacji: route(
    "/termin-instalacji",
    z
      .object({ type: z.literal("registration"), mode: z.literal("ftth") })
      .optional()
  ),
  trwajaPrace: route("/trwaja-prace"),
  ups: route("/ups"),
  wybierzOferte: route("/wybierz-oferte"),
  zalozKonto: route(
    "/zaloz-konto",
    z.object({
      oferta: z.string().optional(),
      mode: mode.optional(),
      amount: z.string().optional(),
      flow: flow.optional(),
    })
  ),
  wybierzNumer: route(
    "/wybierz-numer",
    z.object({
      type: z.literal("registration").optional(),
      mode,
      oferta: z.string(),
    })
  ),
  adresDostawy: route(
    "/adres-dostawy",
    z.object({
      mode,
      zmien: z.string().optional(),
    })
  ),
  simCzyEsim: route(
    "/sim-czy-esim",
    z.object({
      mode,
    })
  ),
  migracjaNumeru: {
    index: route(
      "/migracja-numeru",
      z.object({
        type: z.literal("registration").optional(),
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    otp: route(
      "/migracja-numeru/otp",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
        _t: z.string(),
      })
    ),
    sprawdzMozliwosc: route(
      "/migracja-numeru/sprawdz-mozliwosc",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    akceptacjaWarunkow: route(
      "/migracja-numeru/akceptacja-warunkow",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    prawieGotowe: route(
      "/migracja-numeru/prawie-gotowe",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    typOfertyMobilnej: route(
      "/migracja-numeru/typ-oferty-mobilnej",
      z.object({ mode })
    ),
    prepaid: {
      wybierzDateMigracji: route(
        "/migracja-numeru/prepaid/wybierz-date-migracji",
        z.object({ mode })
      ),
    },
    postpaid: {
      terminMigracji: route(
        "/migracja-numeru/postpaid/termin-migracji",
        z.object({ mode })
      ),
      wybierzDateMigracji: route(
        "/migracja-numeru/postpaid/wybierz-date-migracji",
        z.object({ mode })
      ),
    },
  },
  zamowienieWTrakcie: route("/zamowienie-w-trakcie"),
  potykacz: route(
    "/potykacz",
    z.object({
      amount: z.string().optional(),
    })
  ),
  wybierak: route(
    "/wybierak",
    z
      .object({
        amount: z.string().optional(),
        flow: flow.optional(),
      })
      .optional()
  ),
  idziemyDalej: route(
    "/idziemy-dalej",
    z.object({
      flow: flow.optional(),
      screenVariant: z.enum(["failed", "success"]).optional(),
    })
  ),
};
